<mat-form-field class="mat-form-field">
    <input matInput
           [gcSelectOnClick]="true"
           [placeholder]="placeholder"
           [matAutocomplete]="auto"
           [required]="required"
           [formControl]="formControl"
           (blur)="onLoseFocus()" />

    <button *ngIf="!disableClearButton && formControl.value"
            mat-icon-button
            matSuffix
            aria-label="Clear"
            type="button"
            (click)="onClearSelection()">
        <mat-icon>close</mat-icon>
    </button>

    <mat-autocomplete #auto="matAutocomplete"
                      [displayWith]="displayWith"
                      (optionSelected)="onSelectOption($event)">
        <mat-option [class.multiline]="option.subtext"
                    *ngFor="let option of filteredOptions"
                    [value]="option"
                    [disabled]="option.disabled">
            {{ option.displayName }}
            <small class="subtext"
                   *ngIf="option.subtext">
            {{option.subtext}}
            </small>
        </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="formControl.hasError('required')">Field is required</mat-error>
    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
</mat-form-field>
