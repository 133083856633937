import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PasswordChangeError, PasswordChangeForm, PasswordResetForm, PasswordVerificationDetails } from '@hq-app/login/models/passwords';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Hub, HubCapsule } from '@aws-amplify/core';
import { routeParts } from '@hq-core/routes';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { UserCredentials } from '../models/login';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements OnDestroy {
    private loggedInState = new BehaviorSubject<boolean>(false);

    constructor(
        private router: Router,
    ) {
        Hub.listen('auth', evt => this.onAuthEvent(evt));
        this.isAuthenticated();
    }

    ngOnDestroy(): void {
        this.loggedInState.complete();
    }

    isAuthenticated(): Observable<boolean> {
        // Had to catch the rejected promise here to prevent a console error about a rejected promise
        // Related to: https://github.com/angular/angular/issues/31680
        const userAuthenticationPromise = Auth.currentUserPoolUser()
            .catch(() => false);

        return from(userAuthenticationPromise)
            .pipe(
                catchError(() => of(false)),
                map(isAuthorized => !!isAuthorized),
                tap(val => this.loggedInState.next(val))
            );
    }

    redirectToLogin(url: string): Observable<boolean> {
        return from(this.router.navigate([routeParts.login], {
            queryParams: { redir: url }
        }));
    }

    authenticate(userCredentials: UserCredentials): Observable<CognitoUser> {
        const username = userCredentials.username.toLowerCase().trim();
        const password = userCredentials.password;
        return from(Auth.signIn(username, password));
    }

    logout(): Observable<any> {
        return from(Auth.signOut());
    }

    getAuthToken(): Observable<string> {
        return from(Auth.currentSession())
            .pipe(
                catchError(() => of(null)),
                map(session => session?.getIdToken().getJwtToken())
            );
    }

    /* Returns an Observable of the login state - true means the
       user is logged in */
    getLoginState(): Observable<boolean> {
        return this.loggedInState;
    }

    changePassword(passwordChange: PasswordChangeForm): Observable<string> {
        return from(Auth.currentAuthenticatedUser())
            .pipe(
                switchMap(user => {
                    const request = Auth.changePassword(user, passwordChange.oldPassword, passwordChange.newPassword);
                    return from(request);
                })
            );
    }

    startPasswordReset(username: string): Observable<PasswordVerificationDetails> {
        return from(Auth.forgotPassword(username))
            .pipe(
                map(response => new PasswordVerificationDetails({
                    destination: response?.CodeDeliveryDetails?.Destination
                }))
            );
    }

    finishPasswordReset(passwordResetForm: PasswordResetForm): Observable<boolean> {
        const request = Auth.forgotPasswordSubmit(
            passwordResetForm.username,
            passwordResetForm.verificationCode,
            passwordResetForm.password
        );
        return from(request)
            .pipe(
                catchError(error => {
                    return throwError(new PasswordChangeError({
                        // eslint-disable-next-line no-underscore-dangle
                        name: error.name ?? error.__type
                    }));
                }),
                map(() => true)
            );
    }

    private onAuthEvent(data: HubCapsule) {
        switch (data.payload.event) {
            case 'signIn': {
                this.loggedInState.next(true);
                break;
            }
            case 'signIn_failure':
            case 'signOut': {
                this.loggedInState.next(false);
                break;
            }
            default: {
                break;
            }
        }
    }
}
