import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@hq-core/auth/auth.service';
import { AppModule } from '@hq-core/models/app-module';
import { pageTitles, routeParts } from '@hq-core/routes';
import { ThemeService } from '@hq-core/theme/theme.service';
import { UserService } from '@hq-core/user/user.service';
import { combineLatest, of, Subject, tap } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { NavigationItem } from '../models/navigation';
import { AnalyticsAction, AnalyticsCategory, AnalyticsEvent } from '@hq-core/models/analytics';
import { AnalyticsService } from '@hq-core/analytics/analytics.service';
import { LaunchDarklyFeatureFlag } from '@hq-core/models/feature-flag';
import { FeatureFlagService } from '@cia-front-end-apps/shared/feature-flag/feature-flag.service';

@Component({
    selector: 'hq-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent implements OnInit, OnDestroy {
    @Output() clickNavigationItem = new EventEmitter<void>();

    username: string;
    navigationItems: Array<NavigationItem>;

    private readonly navigationItemsThatShouldBeFirst: Array<NavigationItem> = [
        {
            displayName: pageTitles.dashboard,
            icon: 'home',
            path: `/${routeParts.dashboard}`
        }
    ];

    private readonly navigationItemsThatShouldBeLast: Array<NavigationItem> = [
        {
            displayName: pageTitles.help,
            icon: 'help',
            path: `/${routeParts.help}`
        },
        {
            displayName: pageTitles.logout,
            icon: 'exit_to_application',
            path: `/${routeParts.logout}`
        }
    ];

    private readonly navigationItemsThatRequireModules: Array<NavigationItem> = [
        {
            displayName: pageTitles.productSearch,
            icon: 'search',
            path: `/${routeParts.products}`,
            requiredModule: AppModule.Search
        },
        {
            displayName: pageTitles.enterpriseSystems,
            icon: 'device_hub',
            path: `/${routeParts.enterpriseSystems}`,
            requiredModule: AppModule.EnterpriseSystems
        },
        {
            displayName: pageTitles.ordering,
            icon: 'receipt',
            path: `/${routeParts.orders}`,
            requiredModule: AppModule.Ordering
        },
        {
            displayName: pageTitles.savedItems,
            icon: 'star',
            path: `/${routeParts.savedItems}`,
            requiredModule: AppModule.SavedItems
        },
        {
            displayName: pageTitles.supplierManagement,
            icon: 'local_shipping',
            path: `/${routeParts.suppliers}`,
            requiredModule: AppModule.SupplierManagement,

        },
        {
            displayName: pageTitles.storeManagementTools,
            icon: 'store',
            path: `/${routeParts.storeManagementTools}`,
            requiredModule: AppModule.StoreManagementTools
        },
        {
            displayName: pageTitles.brokerCcOrders,
            icon: 'local_shipping',
            path: `/${routeParts.brokerCcOrders}`,
            requiredModule: AppModule.BrokerCcOrders
        },
        {
            displayName: pageTitles.brokerStores,
            icon: 'store',
            path: `/${routeParts.brokerStores}`,
            requiredModule: AppModule.BrokerServices
        },
        {
            displayName: pageTitles.brokerDataManagement,
            icon: 'bar_chart',
            path: `/${routeParts.brokerDataManagement}`,
            requiredModule: AppModule.BrokerDataManagement
        },
        {
            displayName: pageTitles.vendorMapping,
            icon: 'map',
            path: `/${routeParts.vendorMapping}`,
            requiredModule: AppModule.VendorMapping
        },
        {
            displayName: pageTitles.detaSynq,
            icon: 'sync',
            path: `/${routeParts.detaSynq}`,
            requiredModule: AppModule.DetaSynq
        },
        {
            displayName: pageTitles.internalVendorMapping,
            icon: 'map',
            path: `/${routeParts.internalVendorMapping}`,
            requiredModule: AppModule.InternalVendorMapping
        },
        {
            displayName: pageTitles.userManagement,
            icon: 'supervisor_account',
            path: `/${routeParts.userManagement}`,
            requiredModule: AppModule.UserManagement
        },
        {
            displayName: pageTitles.reports,
            icon: 'description',
            path: `/${routeParts.reports}`,
            requiredModule: AppModule.Reports
        },
        {
            displayName: pageTitles.monitoring,
            icon: 'tv',
            path: `/${routeParts.monitoring}`,
            requiredModule: AppModule.Monitoring
        },
        {
            displayName: pageTitles.promotionsManagement,
            icon: 'build',
            path: `/${routeParts.promotionsManagement}`,
            requiredModule: AppModule.PromotionsManagement
        },
        {
            displayName: pageTitles.systemMessages,
            icon: 'campaign',
            path: `/${routeParts.systemMessages}`,
            requiredModule: AppModule.SystemMessages
        },
        {
            displayName: pageTitles.analytics,
            icon: 'show_chart',
            path: `/${routeParts.analytics}`,
            requiredModule: AppModule.Analytics
        },
        {
            displayName: pageTitles.advertising,
            icon: 'show_chart',
            path: `/${routeParts.advertising}`,
            requiredModule: AppModule.Advertising
        },
        {
            displayName: pageTitles.trainingResources,
            icon: 'model_training',
            path: `/${routeParts.trainingResources}`,
            requiredModule: AppModule.TrainingResources
        },

        {
            displayName: pageTitles.settings,
            icon: 'settings',
            path: `/${routeParts.settings}`,
            requiredModule: AppModule.Settings
        },
        {
            displayName: pageTitles.supplierGenius,
            icon: 'link',
            path: null,
            requiredModule: AppModule.SupplierGenius
        }
    ];

    private unsubscribe = new Subject<void>();

    constructor(
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
        private cdRef: ChangeDetectorRef,
        private themeService: ThemeService,
        private analyticsService: AnalyticsService,
        private featureFlagService: FeatureFlagService
    ) {
    }

    ngOnInit(): void {
        this.username = '';

        this.navigationItems = [
            ...this.navigationItemsThatShouldBeFirst,
            ...this.navigationItemsThatShouldBeLast
        ];

        this.authService.getLoginState()
            .pipe(
                distinctUntilChanged(),
                filter(hasLoggedIn => hasLoggedIn),
                switchMap(hasLoggedIn => this.userService.getCurrentUser()),
                switchMap(user => {
                    // Now we have the user, let's fetch the feature flag
                    return combineLatest({
                        user: of(user),
                        hasSupplierManagementEnabled: this.featureFlagService.getFlag(LaunchDarklyFeatureFlag.ReleaseSupplierManagementBeta),
                        hasSavedItemsEnabled: this.featureFlagService.getFlag(LaunchDarklyFeatureFlag.ReleaseSavedItems)
                    });
                }),
                takeUntil(this.unsubscribe)
            )
            .subscribe(({ user , hasSupplierManagementEnabled, hasSavedItemsEnabled }) => {
                const profile = user.profile;
                this.username = `${profile.firstName} ${profile.lastName}`;

                const appModules = user.modules || [];
                const authorizedNavigationItems = this.getAuthorizedNavigationItems(appModules);

                this.navigationItems = [
                    ...this.navigationItemsThatShouldBeFirst,
                    ...authorizedNavigationItems,
                    ...this.navigationItemsThatShouldBeLast
                ];

                // TODO: Remove this once Supplier Management is ready for production
                if (!hasSupplierManagementEnabled) {
                    this.navigationItems = this.navigationItems.filter(navigationItem => {
                        return navigationItem.requiredModule !== AppModule.SupplierManagement
                    });
                }

                // TODO: Remove this once Saved Items is ready for production
                if (!hasSavedItemsEnabled) {
                    this.navigationItems = this.navigationItems.filter(navigationItem => {
                        return navigationItem.requiredModule !== AppModule.SavedItems
                    });
                }

                this.themeService.updateUserTheme(user);

                this.cdRef.detectChanges();
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    onClickNavigationItem(navigationItem: NavigationItem): void {
        this.checkForLink(navigationItem);

        this.clickNavigationItem.emit();
    }

    onKeyPressed(navigationItem: NavigationItem): void {
        this.checkForLink(navigationItem);

        this.clickNavigationItem.emit();
        this.router.navigate([navigationItem.path]);
    }

    private checkForLink(navigationItem: NavigationItem): void {
        switch (navigationItem.displayName) {
            case pageTitles.supplierGenius:
                window.open('https://suppliergenius.com', '_blank');
                break;
            case pageTitles.detaSynq:
                this.analyticsService.trackEvent(new AnalyticsEvent({
                    action: AnalyticsAction.ClickOrderPlusStatus,
                    category: AnalyticsCategory.OrderPlusTooling,
                }));
                break;
            case pageTitles.supplierManagement:
                this.analyticsService.trackEvent(new AnalyticsEvent({
                    action: AnalyticsAction.ClickSupplierManagement,
                    category: AnalyticsCategory.SupplierManagement,
                }));
                break;
            case pageTitles.savedItems:
                this.analyticsService.trackEvent(new AnalyticsEvent({
                    action: AnalyticsAction.ClickSavedItemsMenuItem,
                    category: AnalyticsCategory.SavedItems,
                }));
                break;
            default:
                break;
        }
    }

    private getAuthorizedNavigationItems(modules: Array<string>): Array<NavigationItem> {
        return this.navigationItemsThatRequireModules.filter(navigationItem => {
            const requiresModule = !!(navigationItem.requiredModule);
            return !requiresModule || modules.includes(navigationItem.requiredModule);
        });
    }
}
