<form [formGroup]="formGroup">
    <div class="form-section">
        <div class="row">
            <mat-form-field class="field">
                <input matInput
                       placeholder="Contact"
                       formControlName="contact">
            </mat-form-field>
            <mat-form-field class="field">
                <input matInput
                       placeholder="Email"
                       formControlName="email">
                <mat-error *ngIf="formGroup.get('email').hasError('email')">
                    Must be a valid email.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="field">
                <input matInput
                       required
                       placeholder="Address 1"
                       formControlName="address1">
                <mat-error *ngIf="formGroup.get('address1').hasError('required')">
                    Field is required.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="field">
                <input matInput
                       placeholder="Address 2"
                       formControlName="address2">
            </mat-form-field>
        </div>
        <div class="row">
            <mat-form-field class="field">
                <input matInput
                       required
                       placeholder="City"
                       formControlName="city">
                <mat-error *ngIf="formGroup.get('city').hasError('required')">
                    Field is required.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="field">
                <input matInput
                       required
                       maxlength="2"
                       placeholder="State"
                       formControlName="state">
                <mat-error *ngIf="formGroup.get('state').hasError('required')">
                    Field is required.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="field">
                <input matInput
                       required
                       placeholder="Postal Code"
                       formControlName="postalCode">
                <mat-error *ngIf="formGroup.get('postalCode').hasError('required')">
                    Field is required.
                </mat-error>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field class="field">
                <mat-select placeholder="Country"
                            required
                            formControlName="country">
                    <mat-option *ngFor="let country of countryOptions"
                                [value]="country">
                        {{country}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.get('country').hasError('required')">
                    Field is required.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="field">
                <input *ngIf="!maskPhone"
                       matInput
                       placeholder="Phone"
                       type="tel"
                       required
                       formControlName="phone">
                <input *ngIf="maskPhone"
                       matInput
                       hqPhoneMask
                       placeholder="Phone"
                       type="tel"
                       required
                       formControlName="phone">
                <mat-error *ngIf="formGroup.get('phone').hasError('required')">
                    Field is required.
                </mat-error>
                <mat-hint *ngIf="maskPhone">
                    Numbers only: (000) 000-0000
                </mat-hint>
            </mat-form-field>
            <mat-form-field class="field">
                <input matInput
                       placeholder="Fax"
                       type="tel"
                       formControlName="fax">
            </mat-form-field>
        </div>
    </div>
</form>
