import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { FormChangeEvent } from '@hq-core/models/forms';
import { displayByName, DropdownOption, filterByDisplayName } from '@hq-shared/models/dropdown-option';
import { TypeIdentity } from '@hq-shared/type-identity/type-identity';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'hq-autocomplete-form-field',
    templateUrl: './autocomplete-form-field.component.html',
    styleUrls: ['./autocomplete-form-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteFormFieldComponent implements OnInit, OnDestroy, OnChanges {
    @Input() options: Array<DropdownOption>;
    @Input() placeholder: string;
    @Input() selectionOverride: DropdownOption;
    @Input() disableClearButton: boolean;
    @Input() required: boolean;
    @Input() disabled: boolean;
    @Input() submitted?: boolean;
    @Input() resetControl = new Subject<void>();
    @Input() hint: string;
    @Output() changeValue = new EventEmitter<FormChangeEvent<DropdownOption>>();
    @Output() markAsInitialized = new EventEmitter<AbstractControl>();

    private unsubscribe = new Subject<void>();
    private blurTimer: any;

    formControl: FormControl;
    filteredOptions = new Array<DropdownOption>();
    readonly displayWith = displayByName;

    ngOnInit(): void {
        const validators = this.required ? [Validators.required] : [];
        this.formControl = new FormControl(
            {
                value: '',
                disabled: true
            },
            {
                validators
            }
        );
        this.filteredOptions = this.options || [];
        this.updateFormInteractiveState();

        this.formControl.valueChanges
            .pipe(
                takeUntil(this.unsubscribe)
            )
            .subscribe((input: any) => {
                const nameFilter = TypeIdentity.isString(input) ? input?.trim() : input?.displayName;
                this.filteredOptions = filterByDisplayName(this.options, nameFilter);
            });

        this.resetControl
            .pipe(
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => this.onClearSelection());

        this.setOverrideSelection();

        this.markAsInitialized.emit(this.formControl);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options && !changes.options.firstChange) {
            this.filteredOptions = this.options || [];
            if (this.filteredOptions.length === 0) {
                this.formControl.setValue('', { emitEvent: false });
            }

            this.updateFormInteractiveState();
        }

        if (changes.selectionOverride && !changes.selectionOverride.firstChange) {
            this.setOverrideSelection();
        }

        if (changes.submitted && !changes.submitted.firstChange) {
            this.formControl.markAsTouched();
        }
    }

    ngOnDestroy(): void {
        clearTimeout(this.blurTimer);
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    onSelectOption(event: MatAutocompleteSelectedEvent): void {
        this.emitSelectionChange(event.option.value);
    }

    onClearSelection(): void {
        this.formControl.setValue('');
        this.emitSelectionChange(null);
    }

    onLoseFocus(): void {
        if (this.selectionOverride) {
            this.blurTimer = setTimeout(() => this.restorePreviousValue(), 600);
        }
    }

    private restorePreviousValue(): void {
        this.formControl.setValue(this.selectionOverride, { emitEvent: false });
    }

    private emitSelectionChange(selectedOption: DropdownOption): void {
        clearTimeout(this.blurTimer);

        if (this.selectionOverride !== selectedOption) {
            const event = new FormChangeEvent<DropdownOption>({
                value: selectedOption,
                isValid: this.formControl.valid || this.formControl.disabled
            });
            this.selectionOverride = selectedOption;
            this.changeValue.emit(event);
        }
    }

    private setOverrideSelection(): void {
        if (this.options && this.selectionOverride !== this.formControl.value) {
            const selectedOption = this.selectionOverride || '';
            this.formControl.setValue(selectedOption);
        }
    }

    private updateFormInteractiveState(): void {
        const shouldControlBeDisabled = this.filteredOptions.length === 0 || this.disabled;
        if (shouldControlBeDisabled) {
            this.formControl.disable();
        } else {
            this.formControl.enable();
        }

        this.disableClearButton = this.disabled || this.disableClearButton;
    }
}
