import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@hq-core/core.module';
import { AppInjector } from '@hq-core/models/app-injector';
import { SharedModule } from '@hq-shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideQueryDevTools } from '@ngneat/query-devtools';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { ANALYTICS_SERVICE } from '@cia-front-end-apps/shared/analytics/analytics';
import { AnalyticsService } from '@hq-core/analytics/analytics.service';
import { provideQueryClientOptions } from '@ngneat/query';

const prerequisiteModules = [
    BrowserModule
];
const isLocalHost = window.location.hostname.indexOf('localhost') > -1;

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        ...prerequisiteModules,
        AppRoutingModule,
        BrowserAnimationsModule,
        CoreModule,
        SharedModule
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        { provide: ANALYTICS_SERVICE, useClass: AnalyticsService },
        provideEnvironmentNgxMask(),
        provideQueryClientOptions({
            defaultOptions: {
                queries: {
                    // we don't want to retry if it's a 401 or 404
                    retry: (failureCount, error) => {
                        const is401 = error['status'] && error['status'] === 401;
                        const is404 = error['status'] && error['status'] === 404;
                        if (is401 || is404) {
                            return false;
                        }
                        return failureCount < 3;
                    }
                },
            },
        }),
        isLocalHost ? provideQueryDevTools() : [],
    ],
})
export class AppModule {
    constructor(private injector: Injector) {
        AppInjector.injector = this.injector;
    }
}
