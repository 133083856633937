export enum FeatureFlag {
    SupportsVendorOrderChanges = 'supportsVendorOrderChanges',
    SupportsInDevelopmentFeatures = 'supportsInDevelopmentFeatures',
    RequiresNameOnOrder = 'requiresNameOnOrder',
    BetaBroker = 'betaBroker',
    LocalVendors = 'localVendors',
    DisplayAllVendorOrders = 'displayAllVendorOrders',
    SupportsPendingProducts = 'supportsPendingProducts',
    InAisleSolutions = 'inAisleSolutions',
}

export enum LaunchDarklyFeatureFlag {
    ReleaseSupplierManagementBeta = 'release-supplier-management-beta',
    ReleaseSavedItems = 'release-saved-items'
}
