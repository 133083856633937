<mat-expansion-panel [expanded]="true"
                     [formGroup]="formGroup">
    <mat-expansion-panel-header>
        <mat-panel-title>
            Advanced Settings
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="upc-correction ">
        <span>UPC Correction:</span>
        <div>
            <mat-slide-toggle formControlName="calculateCheckDigit"
                              labelPosition="after"
                              name="calculate-check-digit">
                Calculate Check Digit
            </mat-slide-toggle>
            <mat-form-field class="trim-selector">
                <mat-select formControlName="totalCharactersToTrim"
                            placeholder="Trim Leading Values"
                            name="trim-leading-values">
                    <mat-option *ngFor="let option of trimOptions"
                                [value]="option">
                        {{ option }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</mat-expansion-panel>
