<form [formGroup]="formGroup">
    <div class="row">
        <mat-form-field class="field">
            <input matInput
                   formControlName="name"
                   placeholder="Supplier Name"
                   maxlength="50">
            <mat-error *ngIf="formGroup.get('name').hasError('required')">
                This field is required
            </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="hasExternalId"
                        class="field">
            <input matInput
                   formControlName="externalVendorId"
                   placeholder="Supplier ID"
                   maxlength="50">
            <mat-error *ngIf="formGroup.get('externalVendorId').hasError('required')">
                This field is required
            </mat-error>
            <mat-error *ngIf="formGroup.get('externalVendorId').hasError('pattern')">
                Supplier ID can only contain numbers, letters, or spaces
            </mat-error>
        </mat-form-field>
    </div>
    <div class="row">
        <mat-form-field class="field">
            <input matInput
                   formControlName="contact"
                   placeholder="Contact">
        </mat-form-field>
        <mat-form-field class="field">
            <input matInput
                   formControlName="email"
                   placeholder="Email (orders will be delivered here)">
            <mat-error *ngIf="formGroup.get('email').hasError('required')">
                This field is required
            </mat-error>
            <mat-error *ngIf="formGroup.get('email').hasError('email') && !formGroup.get('email').hasError('required')">
                Please enter a valid email address
            </mat-error>
        </mat-form-field>
        <mat-form-field class="field">
            <input matInput
                   formControlName="emailCC"
                   placeholder="CC Email">
            <mat-error *ngIf="formGroup.get('emailCC').hasError('email')">
                Please enter a valid email address
            </mat-error>
        </mat-form-field>
    </div>
    <div class="row">
        <mat-form-field class="field">
            <input matInput
                   formControlName="address1"
                   placeholder="Address 1">
            <mat-error *ngIf="formGroup.get('address1').hasError('required')">
                This field is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="field">
            <input matInput
                   formControlName="address2"
                   placeholder="Address 2">
        </mat-form-field>
    </div>
    <div class="row">
        <mat-form-field class="field">
            <input matInput
                   formControlName="city"
                   placeholder="City">
            <mat-error *ngIf="formGroup.get('city').hasError('required')">
                This field is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="field">
            <input matInput
                   formControlName="state"
                   placeholder="State">
            <mat-error *ngIf="formGroup.get('state').hasError('required')">
                This field is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="field">
            <input matInput
                   formControlName="zip"
                   placeholder="Zip Code">
            <mat-error *ngIf="formGroup.get('zip').hasError('required')">
                This field is required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="field">
            <input matInput
                   formControlName="phone"
                   placeholder="Phone">
        </mat-form-field>
    </div>
    <div class="row">
        <mat-form-field class="field">
            <input matInput
                   formControlName="minimumOrderAmount"
                   placeholder="Min Order Amount"
                   type="number"
                   min="0">
        </mat-form-field>
        <mat-form-field class="field">
            <input matInput
                   formControlName="freeShippingAmount"
                   placeholder="Free Shipping Threshold"
                   type="number"
                   min="0">
        </mat-form-field>
    </div>
</form>
