import { BrokerVendorCatalog } from '@hq-app/broker-management/models/broker-vendor-catalog';
import { Broker } from '@hq-app/user-management/models/broker';
import { Department } from '@hq-app/user-management/models/department';
import { ReleaseRole } from '@hq-app/user-management/models/release-role';
import { Vendor } from '@hq-app/user-management/models/vendor';
import { PriceAdjustment } from '@hq-app/vendor-ordering/models/price-adjustments';
import { PackagingType } from '@hq-app/vendor-ordering/models/shipment';
import { Chain } from '@hq-core/models/chain';
import { ChainStore } from '@hq-core/models/chain-store';
import { Address } from '@hq-core/models/contact-information';
import { AppModule } from './app-module';
import { AppRole } from './app-role';
import { FeatureFlag } from './feature-flag';
import { UserGroup } from './user-group';

export class Profile {
    email?: string;
    firstName: string;
    lastName: string;
    username: string;
    address = new Address();

    constructor(options?: Partial<Profile>) {
        Object.assign(this, options);
    }
}

export class UserRegionalDistributor {
    id: number;
    sortIndex: number;

    constructor(options?: Partial<UserRegionalDistributor>) {
        Object.assign(this, options);
    }
}

export class SearchPreferences {
    futureSpecials: number;
    expiredSpecials: number;
    newProducts: number;
    discontinuedProducts: number;
    priceChange: number;

    constructor(options?: Partial<SearchPreferences>) {
        Object.assign(this, options);
    }
}

export class ShippingSettings {
    deliveryDate: number;
    carrier: string;
    packagingType: PackagingType;
    contactName: string;
    contactPhone: string;
    gs1: string;

    constructor(options?: Partial<ShippingSettings>) {
        Object.assign(this, options);
    }
}

export class InvoiceSettings {
    useProfileForRemitToAddress: boolean;
    usePoNumberForInvoiceNumber: boolean;
    remitToAddress = new Address();
    companyName: string;
    contactName: string;
    contactNumber: string;
    invoiceDue: string;
    term: number;
    discountPercent: number;
    termsOfSale: string;
    dunsNumber: string;
    allowances = new Array<PriceAdjustment>();
    charges = new Array<PriceAdjustment>();
    memo: string;

    constructor(options?: Partial<InvoiceSettings>) {
        Object.assign(this, options);
    }
}

export class SubscriptionSetting {
    vendorId: number;
    specialsTypeId: number;

    constructor(options?: Partial<SubscriptionSetting>) {
        Object.assign(this, options);
    }
}

export class Settings {
    searchPreferences = new SearchPreferences();
    subscriptionSpecials = new Array<SubscriptionSetting>();
    invoiceSettings = new InvoiceSettings();
    shippingSettings: ShippingSettings;

    constructor(options?: Partial<Settings>) {
        Object.assign(this, options);
    }
}

/* When retrieving a list of users, this class should be used as APIs will send a truncated form of
   the User object to reduce the payload size */
export class BaseUser {
    id: number;
    appRole: AppRole;
    releaseRole: ReleaseRole;
    profile = new Profile();
    userGroup: UserGroup;

    constructor(options?: Partial<BaseUser>) {
        Object.assign(this, options);
    }
}

export class User extends BaseUser {
    chain = new Chain();
    currentStore = new ChainStore();
    features = new Array<FeatureFlag>();
    modules = new Array<AppModule>();
    settings = new Settings();
    stores = new Array<ChainStore>();
    distributors = new Array<UserRegionalDistributor>();
    vendor = new Vendor();
    broker = new Broker();
    isEnabled: boolean;
    brokerCatalogs = new Array<BrokerVendorCatalog>();
    detaSynQAssignedDepartments = new Array<Department>();

    constructor(options?: Partial<User>) {
        super(options);
        Object.assign(this, options);
    }
}

export class UpsertUser extends User {
    password: string;

    constructor(options?: Partial<UpsertUser>) {
        super(options);
        Object.assign(this, options);
    }
}

export class UserSummary {
    assignedVendor: string;
    assignedBroker: string;
    isRetailerUser: boolean;
    isVendorUser: boolean;
    isBrokerUser: boolean;
    isBuyerUser: boolean;

    constructor(options?: Partial<UserSummary>) {
        Object.assign(this, options);
    }
}

export const userEnableControlErrorMessages = new Map<AppRole, string>([
    [AppRole.Broker, 'User must be assigned to a broker'],
    [AppRole.Retailer, 'User must be assigned to a chain and stores'],
    [AppRole.Vendor, 'User must be assigned to a vendor'],
    [AppRole.Buyer, 'User must be assigned to a chain']
]);
